@if (visible()) {
  <div [ngClass]="modalClasses">
    <div class="relative w-auto" [ngClass]="dialogClasses()">
      <div
        class="font-sarabun border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white"
      >
        @if (closeable()) {
          <button class="flex justify-end mr-2 mt-2" (click)="handleCloseClick()">
            <app-icon name="closeBlack" size="24" />
          </button>
        }
        <ng-content />
      </div>
    </div>
  </div>
  <div class="opacity-70 fixed inset-0 z-40 bg-black top-0 w-full h-full"></div>
}
