import { booleanAttribute, Component, computed, input, output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IconModule } from '@shared/components/v2/icon/icon.component'

@Component({
  selector: 'app-modal',

  imports: [CommonModule, IconModule],
  templateUrl: './modal.component.html',
})
export class ModalComponent {
  public visible = input(false, {
    transform: booleanAttribute,
  })
  public closeable = input(false, {
    transform: booleanAttribute,
  })
  public centered = input(true, {
    transform: booleanAttribute,
  })

  public close = output()

  protected modalClasses = [
    'font-sans',
    'overflow-x-hidden',
    'top-0',
    'overflow-y-auto fixed',
    'inset-0',
    'z-50',
    'outline-none',
    'focus:outline-none',
    'w-full',
    'top-2/4',
    '-translate-y-2/4',
    'h-full',
  ]

  protected dialogClasses = computed(() => {
    let dialogClasses = ['m-[1.5rem]']

    if (this.centered()) {
      dialogClasses = ['flex', 'justify-center', 'items-center', `min-h-[calc(100%-3rem)]`, ...dialogClasses]
    }
    return dialogClasses
  })

  protected handleCloseClick() {
    this.close.emit()
  }
}
